import React from "react";
import { HeroPost, FooterPost } from "../components";
import { Layout } from "../layout";

function Post({location}:any){
	return(
		<Layout location={location}>
			<HeroPost />
			<FooterPost />
		</Layout>
	)
}

export default Post